<div class="user-info" #userInfo>
  <div class="top-info">
    <h1>Elvin Fortes</h1>
    <span>Freelance Fullstack developer</span>
  </div>
  <div>
    <picture class="avater">
      <!-- <source srcset="../../../assets/elvin.jpg" media="(max-width: 600px)" />
          <source srcset="../../../assets/elvin.jpg" media="(max-width: 1500px)" />
          <source srcset="../../../assets/elvin.jpg" /> -->
      <img src="../../../assets/elvin.jpg" alt="Elvin Fortes" width="330" height="330" />
    </picture>
  </div>
  <ul [class.highlight]="isHighlighted" #userDetails class="details">
    <li class="fi flaticon-approve"></li>
    <li>
      <p><span>Naam:</span> Elvin Fortes</p>
    </li>
    <!-- <li>
      <p><span>Ben:</span> Freelancer / ZZPer</p>
    </li> -->
    <li>
      <p class="contact"><span>Telefoon:</span> {{ userService.phone }}</p>
    </li>
    <li>
      <p class="contact"><span>Email:</span> {{ userService.email }}</p>
    </li>
    <li>
      <p class="contact">
        <span>Linkedin: </span>
        <a
          href="https://www.linkedin.com/in/elvin-fortes-9508b121"
          rel="noreferrer"
          target="_blank"
          >Klik hier</a
        >
      </p>
    </li>
     <li>
      <p><span>Woonplaats:</span> Dordrecht</p>
    </li>
    <!--<li>
      <p><span>Verjaardag:</span> 18 feb 1987</p>
    </li> -->
    <li>
      <p><span>KVK</span> 50190792</p>
    </li>
  </ul>
  <app-button
    (onClick)="handleCvRequest($event)"
    center="true"
    iconCls="fa-paper-plane"
    [text]="'Huur mij in'"
  ></app-button>
</div>
