import { AfterContentInit, Component, OnInit } from "@angular/core";
import { Cource } from "src/app/interfaces/cource.interface";
import { CourseService } from "src/app/services/course.service";
import { BulletListItem } from "../bullet-list/bullet-list.component";
import { isMobile as isMobileView } from "is-mobile";
import { AppService } from "src/app/app.service";

const isMobile = isMobileView();

@Component({
  selector: "app-course",
  templateUrl: "./course.component.html",
  styleUrls: ["./course.component.scss"],
})
export class CourseComponent implements OnInit, AfterContentInit {
  title: string = "Certificaten, cursussen en opleidingen";
  Certificaten;
  introText: string = "to come....";

  cources: Array<BulletListItem> = [];

  constructor(
    private courseService: CourseService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.courseService.getCources().subscribe((course: Cource) => {
      this.cources.push({
        title: course.name,
        content: course.description,
        subTitle: course.date,
      });
    });
  }

  ngAfterContentInit() {
    if (isMobile) {
      this.appService.doScrollToContent();
    }
  }
}
