<div class="p15">
  <h1>Wie ben ik?</h1>
  <p>
    Als ZZP’er / Freelancer full stack javascript developer heb ik passie en
    kennis in zowel front als back-end. Sinds 2010 lever ik mijn diensten aan
    kleine en grote bedrijven. Ik kan worden ingehuurd om mee te ontwikkelen aan
    bestaande applicaties of nieuw op te zetten projecten.
  </p>
  <p>
    Inmiddels heb ik projecten mogen uitvoeren voor grote bedrijven als
    <strong>ABN-AMRO, RTL, KPN, LibertyGlobal etc</strong>. Ik heb van deze
    bedrijven een hoop technische kennis opgedaan die ik zoveel mogelijk probeer
    toe te passen in mijn dagelijkse werkzaamheden.
  </p>
  <p>
    Ik ben een gedreven allround webdeveloper die bekend is met de (web)
    gerelateerde standaarden (denk hierbij aan
    <strong
      >Javascript (React, Angular, GraphQL, Redux, NodeJS etc), AWS, serverless,
      HTML 5, CSS 3, XML</strong
    >
    enzovoorts). Mijn focus Ligt op javascript (ook goed bekend met ES6). Ik ben
    ook bekend met de <strong>Amazon cloud (AWS)</strong>. Ik ben
    <strong>AWS gecertificeerd </strong> en in staat complete
    <strong>serverless/non serverless</strong> applicaties te ontwikkelen met
    behulp van AWS diensten als
    <strong
      >Lambda, Stepfunctions, SQS, SNS, Api gateway, S3, DynamoDB, EC2</strong
    >
    etc. Verder heb ik een creatief denkvermogen, ben oplossingsgericht,
    meedenkend, heel zelfstandig, communicatief vaardig, leergierig en een echte
    teamspeler.
  </p>
</div>

<app-testimonials></app-testimonials>
