import { Injectable, Output, EventEmitter } from "@angular/core";

@Injectable()
export class UserInfoService {
  isOpen = false;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  contactRequestClicked() {
    console.log("contactClicked");
    this.change.emit();
  }
}
