<div class="container">
  <div class="grid-layout">
    <aside class="left-panel">
      <app-user-info></app-user-info>
    </aside>
    <header class="header">
      <app-header></app-header>
    </header>
    <section class="content-top">
      <app-availability></app-availability>
    </section>
    <section #mainContent class="content">
      <router-outlet></router-outlet>
    </section>
    <footer class="footer">
      <div>© 2021 All rights reserved - FortesGlobalWeb</div>
    </footer>
  </div>
</div>
