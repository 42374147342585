import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

export enum IconPosition {
  left = "left",
  right = "right"
}

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
export class ButtonComponent implements OnInit {
  @Input() text: string;
  @Input() center: boolean;
  @Input() type: string = "";
  @Input() disabled: boolean = false;
  @Input() iconCls: string;
  @Input() iconPosition: IconPosition = IconPosition.right;

  @Output() onClick = new EventEmitter<MouseEvent>();

  icon: string;

  constructor() {}

  handleClick(event: MouseEvent) {
    this.onClick.emit(event);
  }

  ngOnInit() {}
}
