import { Injectable } from "@angular/core";
import { Project, SubProject } from "../interfaces/projects.interface";
import { of, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  private projects: Array<Project> = [
    // {
    //   name: "ABN-AMRO – New10",
    //   company: "New10 – Onderdeel van ABN-AMRO",
    //   description: `
    //     New10 biedt mkb-ondernemers een volledig digitale oplossing waarmee ze zelf een lening kunnen aanvragen: snel", "simpel en duidelijk.

    //     Ik ben bij New10 ingezet in veel verschillende full stack projecten. Ik ben verantwoordelijk geweest voor het opzetten van veel microservices en de koppeling ervan met de front-end.

    //     Infra in het kort
    //     De UI projecten zijn gekoppeld met enkele Graphql middleware projecten. Deze projecten zorgen dat de Graphql schemas vertaald worden naar de interne microservices / serverless applicaties en enkele monolithische applicaties. Deze applicaties zijn waar mogelijk verbonden met elkaar door middel van meerdere message bussen (AWS kinesis)", "http verzoeken en queues. Hierbij wordt vooral gebruik gemaakt van AWS kinesis en AWS SQS.
    //     Nieuwe functionaliteiten worden ontwikkeld doormiddel van feature toggles. Dit wilt zeggen dat een feature makkelijk aan en uit gezet kan worden. Elke feature die ik ontwikkelde was dus ook backward compatible.
    //     Het meeste wat ik ontwikkelde was gebouwd uit modules die door verschillende teams gebruikt konden worden ( npm modules ).

    //     Enkele belangrijke aandachtspunten van dit bedrijf waar ik mee te maken heb gehad:

    //     -	Unit test
    //     Alle microservices en UI werkzaamheden zijn voorzien van unit testen.
    //     -	End to End test
    //     De hele E2E testen worden uitgevoerd door geprogrammeerd in Cypress. Deze tests worden automatisch uitgevoerd op de dev", "tst en acc omgevingen.
    //     -	Performance
    //     Omdat er vooral gewerkt word met serverless ( lambda ) functies was performance erg belangrijk. De code en de grote van de functies moeten zo optimaal mogelijk werken.
    //     -	CI / CD pipelines
    //     Alle projecten worden gedeployed met behulp van Continuous Integration / Continuous Delivery pipelines.
    //     In de pipelines worden de project cloud resources aangemaakt", "tests uitgevoerd ( unit", "integratie", "system", "integratie", "E2E", "contract testing)", "system deployment etc.
    //     -	Security
    //     -	(system) integratie tests
    //     -	Feature toggles
    //     Alle nieuwe functionaliteiten kunnen doormiddel van feature schakelaars aan en uit worden gezet. Dit zorgt ervoor dat we makkelijk ab-tests kunnen uitvoeren en nieuwe functionaliteiten kunnen testen met een klein groep mensen voordat de volledige implementatie openbaar wordt.
    //     -	Monitoring ( Cloudwatch + Datadog )
    //     -	Logging
    //   `,
    //   role:
    //     "Fullstack developer React / GraphQL / Redux / NodeJS / AWS / Serverless / Microservices",
    //   startDate: new Date("16-01-2018"),
    //   endDate: new Date("16-05-2018"),
    //   subProjects: [
    //     {
    //       title: "",
    //       description: "",
    //       techniques: [""]
    //     }
    //   ]
    // },
    {
      name: "AEGON",
      company: "AEGON NL",
      shortText:
        "De onderneming biedt wereldwijd zo'n 30 miljoen klanten levensverzekeringen, pensioenen en vermogensbeheerproducten aan",
      description: `


      `,
      role: "Fullstack Javascript developer ( Angular / Node.js / AWS )",
      startDate: new Date("2019-06-01"),
      endDate: null,
      techniquesAsText:
        "Angular, TypeScript, Javascript (ES6), Node.js, AWS Lambda / Serverless, AWS",
      subProjects: [
        {
          title: " ",
          description: `       `,
          techniques: [],
        },
        {
          title: "",
          description: `

         `,
          techniques: [],
        },
      ],
    },
    {
      name: "ABN-AMRO – New10",
      company: "New10 – Onderdeel van ABN-AMRO",
      shortText:
        "New10 biedt mkb-ondernemers een volledig digitale oplossing waarmee ze zelf een lening kunnen aanvragen: snel, simpel en duidelijk",
      description: `
        New10 biedt mkb-ondernemers een volledig digitale oplossing waarmee ze zelf een lening kunnen aanvragen: snel", "simpel en duidelijk. 

        Ik ben bij New10 ingezet in veel verschillende full stack projecten. Ik ben verantwoordelijk geweest voor het opzetten van veel microservices en de koppeling ervan met de front-end.
        
        Infra in het kort
        De UI projecten zijn gekoppeld met enkele Graphql middleware projecten. Deze projecten zorgen dat de Graphql schemas vertaald worden naar de interne microservices / serverless applicaties en enkele monolithische applicaties. Deze applicaties zijn waar mogelijk verbonden met elkaar door middel van meerdere message bussen (AWS kinesis)", "http verzoeken en queues. Hierbij wordt vooral gebruik gemaakt van AWS kinesis en AWS SQS.
        Nieuwe functionaliteiten worden ontwikkeld doormiddel van feature toggles. Dit wilt zeggen dat een feature makkelijk aan en uit gezet kan worden. Elke feature die ik ontwikkelde was dus ook backward compatible. 
        Het meeste wat ik ontwikkelde was gebouwd uit modules die door verschillende teams gebruikt konden worden ( npm modules ). 
        
        Enkele belangrijke aandachtspunten van dit bedrijf waar ik mee te maken heb gehad:

        -	Unit test
        Alle microservices en UI werkzaamheden zijn voorzien van unit testen.
        -	End to End test 
        De hele E2E testen worden uitgevoerd door geprogrammeerd in Cypress. Deze tests worden automatisch uitgevoerd op de dev", "tst en acc omgevingen. 
        -	Performance
        Omdat er vooral gewerkt word met serverless ( lambda ) functies was performance erg belangrijk. De code en de grote van de functies moeten zo optimaal mogelijk werken.
        -	CI / CD pipelines
        Alle projecten worden gedeployed met behulp van Continuous Integration / Continuous Delivery pipelines.
        In de pipelines worden de project cloud resources aangemaakt", "tests uitgevoerd ( unit", "integratie", "system", "integratie", "E2E", "contract testing)", "system deployment etc. 
        -	Security 
        -	(system) integratie tests
        -	Feature toggles
        Alle nieuwe functionaliteiten kunnen doormiddel van feature schakelaars aan en uit worden gezet. Dit zorgt ervoor dat we makkelijk ab-tests kunnen uitvoeren en nieuwe functionaliteiten kunnen testen met een klein groep mensen voordat de volledige implementatie openbaar wordt.
        -	Monitoring ( Cloudwatch + Datadog )
        -	Logging
      `,
      role: "Fullstack javascript developer",
      startDate: new Date("2018-01-16"),
      endDate: new Date("2019-05-16"),
      techniques: [],
      subProjects: [
        {
          title: "Lening applicatie UI – Front-end ",
          description: `
          De new10 UI is een React  en Graphql front-end applicatie die de klant de mogelijk geeft verschillende soorten leningen aan te vragen. De klant kan in dit systeem alle benodigdheden voor een lening invoeren. Denk hierbij aan: lening doel", "bedrijfsgegevens", "jaarrekeningen uploaden", "bij en afschrijving upload", "contacten beheer", "contracten tekenen", "documenten tekenen", "identificatie van de gebruiker doormiddel van selfies etc.          
          `,
          techniques: [
            "React",
            "Redux",
            "GraphQL / Apollo",
            "NodeJS",
            "Styled componentsJ",
            "javascript (ES6)",
            "JEST",
            "Cypress E2E",
            "Analytics",
          ],
        },
        {
          title: "Serverless Stride – annual report extractor – Back-end",
          description: `
          Dit systeem heb ik samen met een data analist ontwikkeld om de benodigde jaarcijfers van een jaarrekening te halen. De klant kon zijn jaarrekening uploaden en binnen enkele seconden hadden wij zijn omzet", "winst", "verlies", "schulden", "uitstaande leningen. Dit helpt een klant zonder verstand van een jaarrekening om toch verder in het systeem te komen.

Hoe werkt het?
Klant upload zijn document via de UI  de middleware upload de document naar aws s3  s3 stuurt een event naar AWS SQS  Bericht word opgepakt door Stride en uitgevoerd door AWS Stepfunctions om de juiste cijfers weer te geven. 

         `,
          techniques: [
            "React",
            "Redux",
            "GraphQL / Apollo",
            "NodeJS",
            "Styled componentsJ",
            "javascript (ES6)",
            "AWS",
            "AWS Lambda / Serverless",
            "AWS Kinesis",
            "AWS DynamoDB",
            "AWS StepFunctions",
            "AWS API gateway",
            "AWS SNS",
          ],
        },
      ],
    },
    {
      name: "RTL – Videoland",
      company: "RTL Nederland B.V.",
      shortText:
        "Videoland is een online videodienst met een groot en gevarieerde aanbod van series en films.",
      description: `


      `,
      role: "Fullstack javascript developer",
      startDate: new Date("2016-12-01"),
      endDate: new Date("2017-12-31"),
      techniques: [
        "React / Redux",
        "NodeJS",
        " Javascript (ES6)",
        " Javascript (ES6)",
        " Server side rendering (SSR)",
        " CSS / SASS / BEM",
        " SCRUM",
        " RedisDB ",
        " Webpack",
        " GIT",
        " Jenkins",
        " Unit tests",
        " Nightwatch (E2E)",
        " RabbitMQ",
        " Postgres",
        " JWT",
        " XML",
        " Eslint",
        " Prettier",
        " mocha",
        " Supertest",
        " Babel",
        " End-to-end testen E2E",
        " Unit testen",
        " Integratie testen en UI testen]",
      ],
      subProjects: [
        {
          title: " ",
          description: `       `,
          techniques: [],
        },
        {
          title: "",
          description: `

         `,
          techniques: [],
        },
      ],
    },
    {
      name: "LibertyGlobal - Horizon Go ( Ziggo Go )",
      company: "LibertyGlobal – Ziggo ",
      shortText:
        "Horizon Go biedt je een ruime selectie tv-zenders die je direct kunt bekijken.",
      description: `


      `,
      role: "NodeJS / React developer",
      startDate: new Date("2016-06-16"),
      endDate: new Date("2016-11-30"),
      techniquesAsText:
        "React / Fluxible, NodeJS , Javascript (ES6), SCRUM, AWS DynamoDB, RedisDB , Webpack, GIT, Unit tests, CSS / SASS",
      subProjects: [
        {
          title: " ",
          description: `       `,
          techniques: [],
        },
        {
          title: "",
          description: `

         `,
          techniques: [],
        },
      ],
    },
    {
      name: "KPN - Grip 3.0",
      company: "KPN",
      shortText:
        "Grip van KPN is een Cloud Service Aggeration platform, waarbij de gebruiker met 1 userid toegang heeft tot alle applicaties vanaf elk willekeurig device, ongeacht het besturingssysteem.",
      description: `

      `,
      role: "Front / Back-end developer",
      startDate: new Date("2014-12-01"),
      endDate: new Date("2016-09-31"),
      techniquesAsText: "",
      techniques: [],
      subProjects: [
        {
          title: "Adapter Web Farm",
          description: `

         `,
          techniquesAsText:
            "Javascript, NodeJS, ESLINT,  GIT, Unit tests, REST API, Google API, Externe api’s",
        },
        {
          title: "CLI Deployment tool",
          description: `

         `,
          techniquesAsText: "Javascript, NodeJS, ESLINT,  GIT, Unit tests",
        },
        {
          title: "Password reset manager (PWR)",
          description: `

         `,
          techniquesAsText:
            "Javascript, AngularJS, NodeJS, ExpressJS, GulpJS, ESLINT, MySql, LDAP  database (Active directory), JWT authenticatie, GIT, Jenkins",
        },
        {
          title: "Linux command line tool (COA)",
          description: `

         `,
          techniquesAsText:
            "Javascript,  NodeJS,  XML, ESLINT, ExpressJS, MySql, OpenAM, SsoAdm command line, GIT",
        },
        {
          title: "Grip core (CORE)",
          description: `

         `,
          techniquesAsText:
            "Javascript, NodeJS, ESLINT,  MySql, LDAP  database (Active directory), GIT, Unit tests",
        },
        {
          title: "Provisioning web server (PWS)",
          description: `

         `,
          techniquesAsText:
            "Javascript, NodeJS, ESLINT, ExpressJS, MySql, LDAP  database (Active directory), SCIM, JWT authenticatie, GIT",
        },
        {
          title: "API (BMS)",
          description: `

         `,
          techniquesAsText:
            "Javascript, NodeJS, ESLINT,  ExpressJS, JWT authenticatie, MySql, LDAP  database (Active directory), Unit tests",
        },
        {
          title: "Single Sign On",
          description: `       `,
          techniquesAsText:
            "Javascript, css, sass, html, AJAX, GulpJS, ESLINT, Jenkins",
        },
        {
          title: "",
          description: `

         `,
          techniques: [],
        },
      ],
    },
    {
      name: "Nationale Nederlanden - Powerpointtool",
      company: "Nationale Nederlanden in opdracht van Milk en Media",
      shortText:
        "Met deze tool kan er eenvoudig een mooie presentatie in de huisstijl van Nationale-Nederlanden worden gemaakt via een dynamische web applicatie.",
      description: `

      `,
      role: "Front en backend ontwikkelaar",
      startDate: new Date("2014-08-25"),
      endDate: new Date("2014-11-10"),
      techniquesAsText:
        "PHP, Javascript, Backbone, PHP Kohana, css, sass, html, AJAX",
      techniques: [],
      subProjects: [
        {
          title: " ",
          description: `       `,
          techniques: [],
        },
        {
          title: "",
          description: `

         `,
          techniques: [],
        },
      ],
    },
    {
      name: "Android Stoptober APP",
      company: "Headline Interactive",
      shortText:
        "Na het grote succes in Engeland (in het eerste jaar deden daar al 268.000 rokers mee) omarmt ook Nederland dit jaar  ‘Stoptober’. Via nationale en sociale media hebben bekende én onbekende Nederlanders rokers massaal aangemoedigd om 28 dagen te stoppen met roken.",
      description: `

      `,
      role: "Android app ontwikkelaar",
      startDate: new Date("2014-08-01"),
      endDate: new Date("2014-10-01"),
      techniquesAsText: "",
      techniques: ["Android SDK", "Android"],
      subProjects: [
        {
          title: " ",
          description: `       `,
          techniques: [],
        },
        {
          title: "",
          description: `

         `,
          techniques: [],
        },
      ],
    },

    // {
    //   name: "",
    //   company: "",
    //   shortText: "",
    //   description: `

    //   `,
    //   role: "Fullstack javascript developer",
    //   startDate: new Date("2018-01-16"),
    //   endDate: new Date("2019-05-16"),
    //   techniquesAsText: '',
    //   techniques: [],
    //   subProjects: [
    //     {
    //       title: " ",
    //       description: `       `,
    //       techniques: []
    //     },
    //     {
    //       title: "",
    //       description: `

    //      `,
    //       techniques: []
    //     }
    //   ]
    // }
  ];

  constructor() {}

  getProjects(): Observable<Project> {
    return of(...this.projects);
  }

  /**
   * if techniques are added as text instead of array lets split them
   * @param project
   */
  private getTheTechniques(project: Project | SubProject): Array<string> {
    let techniques = [];
    if (project.techniquesAsText) {
      techniques = project.techniquesAsText.split(",").map((tech) => {
        return tech.trim();
      });
    } else {
      techniques = project.techniques || [];
    }
    return techniques;
  }

  getProjectTechniques(project: Project): Array<string> {
    const tech = project.subProjects.reduce((acc, subProject) => {
      let techniques = [];

      // if techniques are added as text instead of array lets split them
      // if (subProject.techniquesAsText) {
      //   techniques = subProject.techniquesAsText.split(",");
      // } else {
      //   techniques = subProject.techniques;
      // }
      return acc.concat(this.getTheTechniques(subProject));
    }, []);
    const all = new Set([...this.getTheTechniques(project), ...tech]);
    return [...all];
  }
}
