<app-card>
  <div class="card">
    <div class="head">
      <img width="40" height="40" src="{{ testimonial.avater }}" alt="{{ testimonial.name }}" />
      <div>
        <span class="name">{{ testimonial.name }}</span>
        <span class="title">{{ testimonial.title }}</span>
      </div>
    </div>
    <p>
      {{ currentText }}
      <a (click)="toggleText()" *ngIf="displayTextToLong">{{
        displayFullText ? "Lees minder" : "Lees meer"
      }}</a>
    </p>
  </div>
</app-card>
