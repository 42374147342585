import { Injectable, Output, EventEmitter } from "@angular/core";
@Injectable({
  providedIn: "root"
})
export class AppService {
  constructor() {}

  isOpen = false;

  @Output() scrollToContent: EventEmitter<boolean> = new EventEmitter();

  doScrollToContent() {
    this.scrollToContent.emit();
  }
}
