import { Component, OnInit, OnDestroy } from "@angular/core";

import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { UserInfoService } from "../user-info/user-info.service";
import { AppService } from "src/app/app.service";
import { isMobile as isMobileView } from "is-mobile";

const isMobileDevice = isMobileView();

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  toggleMenu: boolean = false;

  isMobile: boolean = isMobileDevice;

  constructor(
    private userInfoService: UserInfoService,
    private router: Router,
    private appService: AppService
  ) {}

  ngOnInit() {
    window.addEventListener("resize", () => {
      this.toggleMenu = false;
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.toggleMenu = false;
      }

      if (e instanceof NavigationEnd) {
        // on initial load don't scroll to the content. Dirty fix
        if (sessionStorage.getItem("hasCodeRunBefore") === null) {
          /** Your code here. **/
          sessionStorage.setItem("hasCodeRunBefore", "true");
          return;
        }
        this.appService.doScrollToContent();
      }
    });
  }

  onContactLinkClicked() {
    this.toggleMenu = false;
    this.userInfoService.contactRequestClicked();
  }

  onMenuClick() {
    this.toggleMenu = this.toggleMenu ? false : true;
  }
}
