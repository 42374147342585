import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl
} from "@angular/forms";
import {
  UserService,
  AvailabilitySignUp,
  Settings
} from "src/app/services/user.service";
import { Observable, Subscribable, Subscription } from "rxjs";

const DEFAULT_STEP = 1;
const SIGNUP_STEP = 2;
const SIGNUP_COMPLETED = 3;

@Component({
  selector: "app-availability",
  templateUrl: "./availability.component.html",
  styleUrls: ["./availability.component.scss"]
})
export class AvailabilityComponent implements OnInit, OnDestroy {
  isAvailable: boolean = false;
  isSignupStep: number = DEFAULT_STEP;

  form: FormGroup;
  availabilitySub: any;
  availableDate: string = "Laden...";
  title: string = "Aanmelden voor mij beschikbaarheid";
  thankYouMessage: string =
    "Bedankt voor je aanmelding. Zodra ik weer beschikbaar ben ontvang je een bericht.";

  errorText: string = null;
  userSettingsSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService
  ) {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(3)]],
      company: [null, [Validators.required, Validators.minLength(2)]],
      email: [null, [Validators.required, Validators.email]]
    });

    this.userSettingsSub = this.userService
      .getSettings()
      .subscribe((settings: Settings) => {
        this.availableDate = settings.availableDateFormatted;
      });

    this.form.valueChanges.subscribe(() => {
      this.errorText = null;
    });
  }

  ngOnInit() {}

  get name() {
    return this.form.get("name") as FormControl;
  }

  get email() {
    return this.form.get("email") as FormControl;
  }

  onSubmit(data: AvailabilitySignUp) {
    if (this.form.invalid) {
      return;
    }
    this.availabilitySub = this.userService.availabilitySignUp(data).subscribe(
      response => {
        this.isSignupStep = SIGNUP_COMPLETED;
      },
      err => {
        console.log(err);
        this.errorText =
          "Jouw gegevens konden niet worden opgeslagen :(. Probeer later opnieuw.";
      }
    );
  }

  signup(event: MouseEvent) {
    this.isSignupStep = SIGNUP_STEP;
  }
  cancelSignup(event: MouseEvent) {
    this.isSignupStep = DEFAULT_STEP;
  }

  ngOnDestroy(): void {
    this.userSettingsSub.unsubscribe();
  }
}
