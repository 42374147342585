import { AfterContentInit, Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { isMobile as isMobileView } from "is-mobile";

const isMobile = isMobileView();

import { BulletListItem } from "../bullet-list/bullet-list.component";
import { ProjectService } from "src/app/services/project.service";
import { map } from "rxjs/operators";
import { format } from "date-fns";
import { Project } from "../../interfaces/projects.interface";
import { AppService } from "src/app/app.service";

@Component({
  selector: "app-experiance",
  templateUrl: "./experiance.component.html",
  styleUrls: ["./experiance.component.scss"],
})
export class ExperianceComponent implements OnInit, AfterContentInit {
  experiances: Array<BulletListItem> = [];

  experianceTitle = "Freelance projecten";

  introText: string = `Hieronder enkele projecten waar ik de afgelopen jaren aan heb gewerkt. Naast
  de onderstaande projecten zijn er nog een groot aantal andere projecten
  waar ik aan heb gewerkt.`;

  constructor(
    private projectService: ProjectService,
    private titleService: Title,
    private metaService: Meta,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Freelance fullstack developer ervaring");
    this.metaService.updateTag({
      name: "description",
      content: this.experianceTitle,
    });

    this.projectService.getProjects().subscribe((project: Project) => {
      const { name, role, startDate, shortText, techniques, endDate } = project;
      this.experiances.push({
        title: `${name} | ${role}`,
        subTitle: `${this.getDate(startDate)} - ${this.getDate(endDate)}`,
        content: shortText,
        tagTitle: "Technieken:",
        tags: this.projectService.getProjectTechniques(project),
      });
    });
  }

  getFormattedDate(startDate, endDate): string {
    return `${this.getDate(startDate)} - ${this.getDate(endDate)}`;
  }

  getDate(date: Date | null | undefined): string {
    if (!date) {
      return "heden";
    }
    return format(date, "dd-MM-yyyy");
  }

  ngAfterContentInit() {
    if (isMobile) {
      this.appService.doScrollToContent();
    }
  }
}
