import { Component, OnInit, Input } from "@angular/core";

import { Testimonial } from "../../models/Testimonial";

@Component({
  selector: "app-testimonial",
  templateUrl: "./testimonial.component.html",
  styleUrls: ["./testimonial.component.scss"]
})
export class TestimonialComponent implements OnInit {
  @Input() testimonial: Testimonial;

  shortText: String = "";
  fullText: String = "";
  currentText: String = "";
  displayTextToLong: Boolean = false;
  displayFullText: Boolean = false;

  constructor() {}

  ngOnInit() {
    this.setShortText();
    this.currentText = this.shortText;
  }

  setShortText() {
    const text = this.testimonial.text;
    const maxTextLength = 100;
    text.trim();
    if (text.length <= maxTextLength) {
      return text;
    }
    this.displayTextToLong = true;

    this.fullText = text;
    this.shortText = text.substring(0, maxTextLength);
  }

  toggleText() {
    this.displayFullText = this.displayFullText ? false : true;
    if (this.displayFullText) {
      this.currentText = this.fullText;
      return;
    }

    this.currentText = this.shortText;
  }
}
