import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UserInfoComponent } from "./components/user-info/user-info.component";
import { ButtonComponent } from "./components/button/button.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/content/content.component";
import { AboutComponent } from "./components/about/about.component";
import { TestimonialsComponent } from "./components/testimonials/testimonials.component";
import { TestimonialComponent } from "./components/testimonial/testimonial.component";
import { CardComponent } from "./components/card/card.component";
import { UserInfoService } from "./components/user-info/user-info.service";
import { BulletListComponent } from "./components/bullet-list/bullet-list.component";
import { ExperianceComponent } from "./components/experiance/experiance.component";
import { ProjectService } from "./services/project.service";
import { AvailabilityComponent } from "./components/availability/availability.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { CourseComponent } from './components/course/course.component';

@NgModule({
  declarations: [
    AppComponent,
    UserInfoComponent,
    ButtonComponent,
    HeaderComponent,
    ContentComponent,
    AboutComponent,
    TestimonialsComponent,
    TestimonialComponent,
    CardComponent,
    BulletListComponent,
    ExperianceComponent,
    AvailabilityComponent,
    CourseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    // AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
  ],
  providers: [UserInfoService, ProjectService],
  bootstrap: [AppComponent],
})
export class AppModule {}
