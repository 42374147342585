<div class="content-box">
  <div *ngIf="isSignupStep === 1" class="availability-container">
    <div>
      <p>
        Ik ben vanaf <span class="date">{{ availableDate }}</span> weer
        beschikbaar. Klik op <a>aanmelden</a> om op de hoogte te blijven van
        mijn schikbaarheid.
      </p>
    </div>
    <div>
      <app-button
        iconCls="fa-paper-plane"
        (onClick)="signup($event)"
        [text]="'Aanmelden'"
      ></app-button>
    </div>
  </div>
  <div *ngIf="isSignupStep === 3">
    <h4>
      {{ thankYouMessage }}
    </h4>
  </div>
  <div *ngIf="isSignupStep === 2" class="availability-form-container">
    <h3>{{ title }}</h3>
    <p class="error" *ngIf="errorText">{{ errorText }}</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
      <div class="form-components">
        <div>
          <mat-form-field>
            <input
              matInput
              formControlName="name"
              placeholder="Wat is jouw naam?"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input
              matInput
              formControlName="email"
              placeholder="Wat is jouw email?"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input
              matInput
              formControlName="company"
              placeholder="Voor welk bedrijf werk je?"
            />
          </mat-form-field>
        </div>
      </div>
      <div>
        <app-button
          iconCls="fa-paper-plane"
          type="submit"
          [text]="'Opslaan'"
          [disabled]="form.invalid"
        ></app-button>
      </div>
    </form>
  </div>
</div>
