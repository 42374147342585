import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./components/about/about.component";
import { CourseComponent } from "./components/course/course.component";
import { ExperianceComponent } from "./components/experiance/experiance.component";

export const routes: Routes = [
  { path: "ervaring", component: ExperianceComponent },
  { path: "certificaten", component: CourseComponent },
  { path: "**", component: AboutComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
