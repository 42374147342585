import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.titleService.setTitle(
      "Fullstack Javascript developer ( Angular / Node.js / React / Serverless / AWS )"
    );
    this.metaService.updateTag({
      name: "description",
      content: "Freelance fullstack javascript developer",
    });
  }
  // handled elsewhere
  // ngAfterContentInit() {
  //   this.appService.doScrollToContent();
  // }
}
