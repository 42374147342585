import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy
} from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { AppService } from "./app.service";
import { isMobile as isMobileView } from "is-mobile";

const isMobile = isMobileView();

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "fgw-web-angular";

  @ViewChild("mainContent", { read: ElementRef })
  private mainContentRef: ElementRef;

  constructor(private appService: AppService) {}

  ngOnInit() {
    if (isMobile) {
      this.appService.scrollToContent.subscribe(() => {
        window.scrollTo({
          top: this.mainContentRef.nativeElement.offsetTop,
          behavior: "smooth"
        });
      });
    }
  }
}
