<button
  [ngClass]="{
    center: center !== undefined,
    'icon-right': iconPosition === 'right'
  }"
  (click)="handleClick($event)"
  [type]="type"
  [disabled]="disabled"
>
  {{ text }}
  <i *ngIf="iconCls" [ngClass]="'fa ' + iconCls" aria-hidden="true"></i>
</button>
