export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBPwpLaBirAbekJghbCEBoJJk0qr6iN9zU",
    authDomain: "fortesglobalweb.firebaseapp.com",
    databaseURL: "https://fortesglobalweb.firebaseio.com",
    projectId: "fortesglobalweb",
    storageBucket: "",
    messagingSenderId: "58596519181",
    appId: "1:58596519181:web:ecb2a0b0175e41bb50d444",
    measurementId: "G-V6FFYG9GSY"
  }
};
