import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Cource } from "../interfaces/cource.interface";

@Injectable({
  providedIn: "root",
})
export class CourseService {
  private courses: Array<Cource> = [
    {
      name:
        "Angular 8+: Learn Angular Core in Depth – Advanced (Door Angular University)",
      description: `This course will give you a solid foundation on the Angular platform by giving you with an in-depth guide tour of all the features available in the Angular Core and Common modules.
      These are the baseline modules from which all other modules in the Angular ecosystem are built with, so this is the part of Angular that you want to learn first, and in as much detail as possible
      `,
      date: "Juni 2019",
    },
    {
      name: "AWS Certified Developer – Associate",
      description: `This exam validates proficiency in developing, deploying, and debugging cloud-based applications using AWS`,
      date: "Januari 2019",
    },
    {
      name:
        "AWS (amazon) Certified Developer - Associate level 2018 (cursus) ( acloud.guru ) v2. Tweede vernieuwde cursus.",
      description: `Learn how to develop/script on Amazon Web Services and pass the AWS Certied Developer`,
      date: "Januari 2019",
    },
    {
      name:
        "AWS Certified Developer - Associate level 2018 (cursus) ( acloud.guru ) ",
      description: `Learn how to develop/script on Amazon Web Services and pass the AWS Certied Developer`,
      date: "Maart 2018",
    },
    {
      name:
        "GraphQL with React: The Complete Developers Guide (Door Stephen Grider)",
      description: `This course will get you up and running with GraphQL / Apollo quickly, and teach you the core knowledge you need to deeply understand and build React applications quickly`,
      date: "Januari 2018",
    },
    {
      name: "Advanced Javascript certificaat (Door A. Hussain)",
      description: `Senior javascript guru`,
      date: "December 2017",
    },
    {
      name: "Powering Up With React (ReactJS)",
      description: `Learn how to build user interfaces for the web with React`,
      date: "Juli 2016",
    },
    {
      name: "Marvels of MongoDB (Nog niet afgerond)",
      description: `Conjure MongoDB and journey into a NoSQL world of documents`,
      date: "Januari 2016",
    },
    {
      name: "ES2015 (ES6 / Javascript 6): The Shape of JavaScript to Come",
      description: `Rock through some of the newest and most interesting features of this updated version of JavaScript`,
      date: "December 2015",
    },
    {
      name: "Adventures in Web Animations (CSS 3)",
      description: `Journey through the wonderful world of web animations`,
      date: "September 2015",
    },
    {
      name: "Building Blocks of Express.js - Javascript / Node.js",
      description: `Learn how to build Web APIs in Node.js using Express`,
      date: "Januari 2015",
    },
    {
      name: "Regular Expressions Basics",
      description: `Learn the basics of Regular expressions`,
      date: "April 2015",
    },
    {
      name: "Node.js - Real-time Web",
      description: `Build lightweight, real-time applications with Node.js`,
      date: "December 2014",
    },
    {
      name: "Angular JS - Shaping up",
      description: `Learn to extend HTML's syntax with AngularJS to create dynamic web applications`,
      date: "December 2014",
    },
    {
      name: "Jquery - Try JQuery",
      description: `Learn the building blocks of jQuery`,
      date: "December 2014",
    },
    {
      name: "JavaScript - Road Trip Part 1 @CodeSchools",
      description: `An introduction to the basics of the JavaScript language.`,
      date: "December 2014",
    },
    {
      name: "W3 JAVASCRIPT Gecertificeerd (passed the exam with Excellence)",
      description: `Dit JAVASCRIPT certificaat voor ontwikkelaars bewijst fundamentele kennis van web ontwikkeling met behulp van JAVASCRIPT`,
      date: "December 2012",
    },
    {
      name: "W3 CSS Gecertificeerd",
      description: `Dit CSS certificaat voor ontwikkelaars bewijst fundamentele kennis van web ontwikkeling met behulp van CSS`,
      date: "December 2012",
    },
    {
      name: "W3 HTML Gecertificeerd",
      description: `Dit HTML certificaat voor ontwikkelaars bewijst fundamentele kennis van web ontwikkeling met behulp van HTML`,
      date: "December 2012",
    },
    {
      name: "Applicatie ontwikkelaar",
      description: ``,
      date: "2010",
    },
    {
      name: "Internationale groothandel opleiding",
      description: ``,
      date: "2006",
    },
  ];

  constructor() {}

  getCources(): Observable<Cource> {
    return of(...this.courses);
  }
}
