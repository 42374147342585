<div class="home">
  <a title="Homepagina" routerLink="/"><i class="fa fa-home" aria-hidden="true"></i></a>
</div>
<ul [class.mobile-menu]="toggleMenu" class="desktop">
  <li *ngIf="isMobile">
    <a title="Homepagina" routerLink="/">Home</a>
  </li>
  <li>
    <a routerLink="/ervaring">Ervaring</a>
  </li>
  <li>
    <a routerLink="/certificaten">Cursus / certificaten</a>
  </li>
  <li>
    <a (click)="onContactLinkClicked()">Contact</a>
  </li>
</ul>
<div (click)="onMenuClick()" class="hamburger">
  <i class="fa fa-bars"></i>
</div>
