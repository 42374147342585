import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UserInfoService } from "./user-info.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"]
})
export class UserInfoComponent implements OnInit {
  @ViewChild("userDetails", { read: ElementRef })
  private userDetailsEl: ElementRef;

  public isHighlighted: boolean = false;
  private timeout;

  constructor(
    private userInfoService: UserInfoService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.userInfoService.change.subscribe(() => {
      window.scrollTo({
        top: this.userDetailsEl.nativeElement.offsetTop,
        behavior: "smooth"
      });
      clearTimeout(this.timeout);
      this.isHighlighted = true;
      this.timeout = setTimeout(() => {
        this.isHighlighted = false;
      }, 3000);
    });
  }

  handleCvRequest(event: MouseEvent) {
    alert(
      `Op dit moment is dit functionaliteit nog niet beschikbaar. Stuur mij een e-mail ( ${
        this.userService.email
      } ) of neem telefonish contact op ( ${this.userService.phone} ).`
    );
  }
}
