import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Testimonial } from "../../models/Testimonial";

@Component({
  selector: "app-testimonials",
  templateUrl: "./testimonials.component.html",
  styleUrls: ["./testimonials.component.scss"]
})
export class TestimonialsComponent implements OnInit {
  @ViewChild("movablePart") movablePart: ElementRef;
  testimonials: Array<Testimonial> = [
    {
      name: "Carmein Elzer",
      title: "Recruiter",
      text:
        "Toen ik op zoek was naar kandidaat voor mijn klant heb ik Elvin benaderd. Elvin is duidelijk in zijn communicatie en weet wat hij wil. Hij is een enthousiast persoon en komt zijn afspraken na. Na een aantal goede gesprekken is hij op gesprek gegaan bij mijn klant. Inmiddels werkt Elvin nu 2 maanden bij de klant. Door een goede eerlijke communicatie ben ik niet voor verassingen komen te staan. Ik zou ik Elvin direct weerbellen wanneer ik een opdracht krijg van een klant. Ik ben erg tevreden over mijn samenwerking met Elvin.",
      avater: "../../../assets/carmein.jpeg"
    },
    {
      name: "Roel Willemse",
      title: "UX/UI Designer",
      text:
        "Met Elvin samen aan een innovatief project gewerkt gedurende 12 weken. Een prettige samenwerking met een communicatief vaardig en vakbekwame professional. Ook maanden na het project is Elvin bereid zelfs in het weekend mee te werken aan een feature in het systeem.",
      avater: "../../../assets/roel.jpeg"
    },
    {
      name: "Michael Luiken",
      title: "Founder Works360",
      text:
        "Elvin is een enthousiast en volhoudend persoon, zeer creatief en betrokken bij zijn relaties. Hij zorgt er voor dat deadlines gehaald worden zonder dat de kwaliteit achteruit gaat. Het is dan ook erg prettig om met Elvin samen te werken. Tot mijn conclusie ben ik erg tevreden! Hopend op een goede samenwerking voor de toekomst.",
      avater: "../../../assets/michael.jpeg"
    },
    {
      name: "Asim Kaymak",
      title: "UX/UI Designer",
      text:
        "Als student was ik al een hele lange tijd opzoek naar een ervaren ontwikkelaar die mij kon helpen met Iphone App in de AppStore te zetten. Toen ik Elvin aan de telefoon kreeg was hij zo enthousiast over mijn App dat hij gelijk met een’’’JA’’ beantwoordde. Hij heeft me daarna zoals beloofd perfect geholpen. Wat mij opviel was dat Elvin communicatief vaardig heel sterk was, en legde alles stap voor stap uit. Bedankt Elvin!",
      avater: "../../../assets/asim.jpeg"
    }
  ];

  page1Active: boolean = true;
  page2Active: boolean = false;

  constructor() {}

  ngOnInit() {}

  showPage(event) {
    //#movablePart
    let x: string = "0px";

    // TODO make active page dynamic. This is bad
    this.page1Active = false;
    this.page2Active = false;
    switch (parseInt(event.currentTarget.dataset.page)) {
      case 1:
        x = "0px";
        this.page1Active = true;
        break;
      case 2:
        x = "-385px";
        this.page2Active = true;
        break;
    }
    if (window.innerWidth > 768) {
      this.movablePart.nativeElement.style.transform = `translateX(${x})`;
    }
  }

  //transform: translateX(-71px);
}
