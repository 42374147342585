import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import {
  AngularFirestore,
  QuerySnapshot,
  QueryDocumentSnapshot
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { format } from "date-fns";

export interface AvailabilitySignUp {
  name: string;
  email: string;
  company: string;
  sendAvailabilityNotification: boolean;
  created: Date;
}

export interface Settings {
  availableDate: Date;
  availableDateFormatted: string;
}

const CUSTOMER_DOCUMENT = "customers";
const SETTINGS = "settings";

@Injectable({
  providedIn: "root"
})
export class UserService {
  public email: string = "efortes@fortesglobalweb.nl";
  public phone: string = "0615124615";
  // private settings:Settings
  constructor(private db: AngularFirestore) {}

  getSettings(): Observable<Settings> {
    // TODO fetch only once
    return from(this.db.firestore.collection(SETTINGS).get()).pipe(
      map((data: QuerySnapshot<any>) => {
        if (data.docs.length < 1) {
          return null;
        }

        const doc: QueryDocumentSnapshot<any> = data.docs[0];
        const settings = doc.data();
        settings.availableDate = settings.availableDate.toDate();
        settings.availableDateFormatted = format(
          settings.availableDate,
          "dd-MM-yyyy"
        );
        return settings as Settings;
      })
    );
  }

  availabilitySignUp(data: AvailabilitySignUp): Observable<Object> {
    data.sendAvailabilityNotification = true;
    data.created = new Date();
    return from(this.db.firestore.collection(CUSTOMER_DOCUMENT).add(data));
  }
}
