<div class="icon fa" [ngClass]="iconClass"></div>
<div *ngIf="title" class="fa-graduation-cap heading clearfix">
  <h2>{{ title }}</h2>
</div>
<ul class="bullet-list">
  <!-- <li class="fi flaticon-approve clearfix">
      <h1>{{ title }}</h1>
    </li> -->
  <li class="" *ngFor="let item of items">
    <h3>{{ item.title }}</h3>
    <span class="sub-title">{{ item.subTitle }}</span>
    <p>
      {{ item.content }}
    </p>
    <div *ngIf="item.tags && item.tags.length > 0" class="tag-container">
      <span *ngIf="item.tagTitle">{{ item.tagTitle }} </span>
      <span class="tag" *ngFor="let tag of item.tags">{{ tag }}</span>
    </div>
  </li>
</ul>
