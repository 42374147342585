import { Component, OnInit, Input } from "@angular/core";

export interface BulletListItem {
  title?: string;
  subTitle?: string;
  tagTitle?: string;
  content?: string;
  tags?: Array<String>;
}

@Component({
  selector: "app-bullet-list",
  templateUrl: "./bullet-list.component.html",
  styleUrls: ["./bullet-list.component.scss"],
})
export class BulletListComponent implements OnInit {
  @Input() title: string;

  @Input() items: Array<BulletListItem> = [];

  @Input() mainIcon: string = "fa-suitcase"; // fa-graduation-cap fa-suitcase
  iconClass: string;

  constructor() {}

  ngOnInit() {
    this.iconClass = ["icon", "fa", this.mainIcon].join(" ");
  }
}
