<div class="container">
  <h3 class="p15">Aanbevelingen</h3>
  <div class="items">
    <!-- [style.transform]="transform" -->
    <div class="movable" #movablePart>
      <app-testimonial
        [testimonial]="testimonial"
        *ngFor="let testimonial of testimonials"
      >
      </app-testimonial>
    </div>
    <div class="nav">
      <div
        #page1
        data-page="1"
        (click)="showPage($event)"
        class="page"
        [class.active]="page1Active"
      ></div>
      <div
        #page2
        data-page="2"
        (click)="showPage($event)"
        [class.active]="page2Active"
        class="page"
      ></div>
    </div>
  </div>
</div>
